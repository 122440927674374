<template>
  <div v-if="!logined">
    <!-- Login -->
    <input v-model="passwd" placeholder="Password" />
    <n-button type="primary" @click="login" :loading="loading">Login</n-button>
  </div>
  <div v-else>
    <div v-for="(provider, index) in provider_list" :key="provider.name">
      <p>Name: {{ provider.name }}</p>
      <p>Type: {{ provider.type }}</p>
      <p>Url: {{ provider.url }}</p>
      <n-button @click="deleteprovider(index)">Delete</n-button>
    </div>
    <div id="add_provider">
      <n-card>
      <n-space vertical>
        <input
          type="text"
          v-model="add_provider_name"
          placeholder="Provider Name"
        />
        <n-radio-group
          v-model:value="add_provider_type"
          name="radiobuttongroup1"
        >
          <n-radio-button
            v-for="(provider_type_label, provider_type) in provider_type_list"
            :key="provider_type"
            :value="provider_type"
          >
            {{ provider_type_label }}
          </n-radio-button>
        </n-radio-group>
        <input v-model="add_provider_url" placeholder="Provider URL" />
        <n-button type="primary" :loading="loading" @click="addprovider"
          >Add</n-button
        >
      </n-space>
      </n-card>
    </div>
    <p>{{ get_config_url }}</p>
  </div>
</template>

<script>
import axios from "axios";
import sha256 from "crypto-js/sha256";

let loadConfig = async function () {
  try {
    let res = await axios.get("/api/get_provider_list");
    if (res.status === 200) {
      return res.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default {
  name: "App",
  data() {
    return {
      add_provider_name: "",
      add_provider_type: "SIP003",
      add_provider_url: "",
      provider_type_list: {
        SIP008: "订阅(raw)",
        SIP003: "单个节点",
        clash: "订阅(clash)",
      },
      loading: false,
      logined: false,
      passwd: "",
      provider_list: [],
    };
  },
  components: {},
  methods: {
    login: async function () {
      this.loading = true;
      if (this.passwd === "") {
        console.log("password is empty");
        this.loading = false;
        return;
      }
      let token = sha256(this.passwd).toString();
      document.cookie = `token=${token}`;
      try {
        let res = await axios.get("/api/checkauth");
        if (res.status === 200) {
          this.provider_list = await loadConfig();
          this.passwd = "";
          this.loading = false;
          this.logined = true;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
        this.logined = false;
      }
      try{
        let res = await axios.get('/api/get_config_url');
        if (res.status === 200) {
          this.get_config_url = res.data;
        }
      }
      catch(e){
        console.log(e);
      }
    },
    deleteprovider: async function (index) {
      try {
        this.loading = true;
        let res = await axios.post("/api/set_provider", {
          type: "delete",
          config: {
            name: this.provider_list[index].name,
            type: this.provider_list[index].type,
            url: this.provider_list[index].url,
          },
        });
        if (res.status === 200) {
          this.provider_list = await loadConfig();
          this.loading = false;
        } else {
          alert("delete provider failed");
          console.log(res);
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    addprovider: async function () {
      this.loading = true;
      for (let provider of this.provider_list) {
        if (provider.name === this.add_provider_name) {
          alert("Provider name already exists");
          return;
        }
      }
      try {
        let res = await axios.post("/api/set_provider", {
          type: "write",
          config: {
            name: this.add_provider_name,
            type: this.add_provider_type,
            url: this.add_provider_url,
          },
        });
        if (res.status === 200) {
          this.provider_list = await loadConfig();
          this.loading = false;
        } else {
          alert("Add provider failed");
          console.log(res);
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
